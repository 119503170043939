// @flow strict
import { useStaticQuery, graphql } from 'gatsby'

const useGitHubRepos = () => {
  const { githubData } = useStaticQuery(
    graphql`
      query GITHUB_REPOS {
        githubData {
          data {
            panOsPython {
              stargazers {
                totalCount
              }
              primaryLanguage {
                name
                color
              }
            }
            panGo {
              stargazers {
                totalCount
              }
              primaryLanguage {
                name
                color
              }
            }
            splunkApps {
              stargazers {
                totalCount
              }
              primaryLanguage {
                name
                color
              }
            }
            ansiblePan {
              stargazers {
                totalCount
              }
              primaryLanguage {
                name
                color
              }
            }
            panOsAnsible {
              stargazers {
                totalCount
              }
              primaryLanguage {
                name
                color
              }
            }
            terraformProvider {
              stargazers {
                totalCount
              }
              primaryLanguage {
                name
                color
              }
            }
            passportCortex {
              stargazers {
                totalCount
              }
              primaryLanguage {
                name
                color
              }
            }
          }
        }
      }
    `,
  )

  return githubData.data
}

export default useGitHubRepos
